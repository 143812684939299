import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';

const Content = ({
    children,
    ...props
}: { children: React.ReactNode } & FlexProps) => (
    <Flex width="100%" justifyContent="center" {...props}>
        <Box minH="xs" w="3xl" maxW="4xl" py="5" px={4} width="100%">
            {children}
        </Box>
    </Flex>
);

export default Content;

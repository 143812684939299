import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex, useToken } from '@chakra-ui/react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Content from '../Content';
import BgImage from '../BgImage';

const NavWrapper = ({
    children,
    showBg,
    bgData,
}: {
    children: React.ReactNode;
    showBg: boolean;
    bgData?: string | null;
}) => {
    const [bgColour] = useToken('colors', ['gray.600']);
    return showBg ? (
        <BgImage imageSrc={bgData} bgAlpha={0.3} bgColour={bgColour}>
            {children}
        </BgImage>
    ) : (
        children
    );
};

const ContentWrapper = ({
    wrapContent,
    children,
}: {
    wrapContent: boolean;
    children: React.ReactNode;
}) => (wrapContent ? <Content>{children}</Content> : children);

const Layout = ({ children }: { children: React.ReactNode }) => {
    const data = useStaticQuery<Queries.LayoutQuery>(graphql`
        query Layout {
            codeBackground: file(
                sourceInstanceName: { eq: "images" }
                relativePath: { eq: "code-background-blue.png" }
            ) {
                childImageSharp {
                    original {
                        width
                        height
                        src
                    }
                }
            }
        }
    `);

    // @ts-ignore
    const showBg = children?.props?.pageContext?.hideNavBg !== true;
    const bgData = data?.codeBackground?.childImageSharp?.original?.src;
    // @ts-ignore
    const wrapContent = children?.props?.pageContext?.wrapContent !== false;

    return (
        <main>
            <Box as="header">
                <NavWrapper showBg={showBg} bgData={bgData}>
                    <Navbar />
                </NavWrapper>
            </Box>
            <Flex
                as="article"
                width="100%"
                justifyContent="center"
                flexDir="column"
            >
                <ContentWrapper wrapContent={wrapContent}>
                    {children}
                </ContentWrapper>
            </Flex>
            <Flex as="footer" width="100%" justifyContent="center" mt={6}>
                <Footer />
            </Flex>
        </main>
    );
};

export default Layout;

import React from 'react';
import { Box } from '@chakra-ui/react';

const DevWindowSize = () => (
    <Box
        position="fixed"
        top="3px"
        right="2px"
        bg="gray.800"
        color="whiteAlpha.800"
        py={1}
        px={2}
        borderRadius="md"
        textAlign="center"
        fontSize="xs"
        zIndex="tooltip"
        sx={{
            '&:before': {
                // eslint-disable-next-line quotes
                content: ["'xs'", "'sm'", "'md'", "'lg'", "'xl'", "'2xl'"],
            },
        }}
    />
);

export default DevWindowSize;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Highlight, themes, Prism } from 'prism-react-renderer';
import { Box } from '@chakra-ui/react';

/* add other languages to Prism */
(typeof global !== 'undefined' ? global : window).Prism = Prism;
// @ts-ignore
import('prismjs/components/prism-markup-templating');
// @ts-ignore
import('prismjs/components/prism-php');
// @ts-ignore
import('prismjs/components/prism-java');
// @ts-ignore
import('prismjs/components/prism-groovy');
// @ts-ignore
import('prismjs/components/prism-textile');

const Code = ({
    children,
    className: codeClassName,
}: {
    children?: React.ReactNode;
    className?: string;
}) => (
    <Box
        sx={{
            '& pre[class*="language-" ], & code[class*="language-"]': {
                whiteSpace: 'break-spaces',
                overflow: 'auto',
                wordBreak: 'break-word',
            },
        }}
    >
        <Highlight
            theme={themes.nightOwl}
            // @ts-ignore
            code={children}
            language={codeClassName?.replace(/language-/, '') || ''}
        >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Box
                    as="pre"
                    className={className}
                    style={{ ...style }}
                    py={2}
                    px={3}
                    fontSize="sm"
                    sx={{
                        '& > div:last-of-type': {
                            mb: 0,
                        },
                    }}
                >
                    {tokens.map((line, index) => {
                        if (line.length <= 1 && line[0]?.empty === true) {
                            return null;
                        }
                        const lineProps = getLineProps({
                            line,
                            key: index,
                        });
                        return (
                            <Box as="div" key={index} {...lineProps} my={1}>
                                {line.map((token, key) => (
                                    <Box
                                        as="span"
                                        key={key}
                                        {...getTokenProps({ token, key })}
                                    />
                                ))}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Highlight>
    </Box>
);

export default Code;

import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';

type ImageData = {
    imageSrc?: string | null;
};

type ImageDataWithBg = {
    bgColour?: string;
    bgAlpha?: number;
};

const getBackgroundImageValue = ({
    imageSrc,
    ...args
}: ImageData | (ImageData & ImageDataWithBg)) => {
    if ('bgColour' in args && 'bgAlpha' in args) {
        const { bgColour, bgAlpha } = args as ImageDataWithBg;
        const bgColourWithGradient = tinycolor(bgColour)
            .setAlpha(bgAlpha || 0.8)
            .toRgbString();
        return `linear-gradient(${bgColourWithGradient}, ${bgColourWithGradient}), url('${imageSrc}')`;
    }
    return `url('${imageSrc}')`;
};

const BgImage = ({
    imageSrc,
    // @ts-ignore
    bgAlpha,
    // @ts-ignore
    bgColour,
    ...props
}: ImageData | ((ImageData & ImageDataWithBg) & BoxProps)) => (
    <Box
        as="section"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="relative"
        opacity="0.99"
        _before={{
            zIndex: -100,
            backgroundImage: getBackgroundImageValue({
                bgColour,
                bgAlpha,
                imageSrc,
            }),
            opacity: 1,
            content: "''",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: '#164194',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-Repeat',
            backgroundSize: 'cover',
        }}
        {...props}
    />
);

export default BgImage;

import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import rot from 'rot13-cipher';
import DevWindowSize from './src/components/DevWindowSize';
import MDXProvider from './src/providers/MDXProvider';
import Layout from './src/components/Layout';

const addJS = (jsCode: string) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerText = jsCode;
    document.getElementsByTagName('head')[0].appendChild(s);
};

const injectFontsScript = () => {
    addJS(`
    WebFontConfig = {
        google: { families: [ 'Roboto+Slab::latin' ] }
      };
      (function() {
        var wf = document.createElement('script');
        wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        wf.type = 'text/javascript';
        wf.async = 'true';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wf, s);
      })();
  `);
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
}) => (
    <>
        {/* required to fix  https://github.com/chakra-ui/chakra-ui/issues/1915 */}
        <style>{'body { margin-right: 0 !important }'}</style>
        {process.env.NODE_ENV === 'development' && <DevWindowSize />}
        <Layout>{element}</Layout>
    </>
);

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
    element,
    ...args
}) => <MDXProvider {...args}>{element}</MDXProvider>;

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {};

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
    () => {
        injectFontsScript();
    };

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
    // decrypt any encrypted emails
    const allAElements = document.getElementsByTagName('a');
    Array.from(allAElements).forEach((el) => {
        const { href } = el;
        if (href.indexOf('mailto:') === 0) {
            const rot13Email = href
                .replace('mailto:', '')
                .replace('__ENCRYPTED__', '');
            const email = rot(rot13Email).replace('_AT_', '@');
            el.setAttribute('href', `mailto://${email}`);
        }
    });

    setTimeout(() => {
        const { hash } = location;
        if (hash?.length > 0) {
            // scroll to anchor tag
            const hashElement = document.getElementById(hash.replace(/^#/, ''));
            if (hashElement) {
                hashElement.scrollIntoView();
            }
        } else {
            // scroll to top
            document.body.scrollTo(0, 0);
        }
    }, 0);
};

export default {
    title: 'Musings of a Software Engineer',
    subtitle:
        'Thoughts and opinions inspired by life in the Software Engineering industry',
    description:
        'Thoughts and opinions inspired by life in the Software Engineering industry',
    siteUrl:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:8000'
            : 'https://andy-berry.co.uk',
    prodSiteUrl: 'https://andy-berry.co.uk',
    keywords: [
        'software',
        'engineer',
        'architect',
        'posts',
        'blog',
        'edinburgh',
    ],
    copyright: 'All rights reserved.',
    disqusShortname: '',
    postsPerPage: 4,
    googleAnalyticsId: 'UA-10944682-2',
    menu: [
        {
            label: 'Home',
            path: '/',
        },
        {
            label: 'Posts',
            path: '/posts',
        },
        {
            label: 'About',
            path: '/#about-andy',
        },
        {
            label: 'Projects',
            path: '/#projects',
        },
        {
            label: 'Experience',
            path: '/experience',
        },
    ],
    footerLinks: [
        {
            label: 'Home',
            path: '/',
        },
        {
            label: 'CV',
            path: '/experience#cv',
        },
        {
            label: 'License',
            path: '/license/',
        },
        {
            label: 'Sitemap',
            path: '/sitemap/',
        },
    ],
    author: {
        name: 'Andy Berry',
        intro: 'A Software Engineering Leader & Developer',
        pronouns: 'he/him',
        photo: '/andy-berry-bio-photo.png',
        bio: 'Thoughts and opinions inspired by life in the Software Engineering industry',
        contacts: {
            email: 'andy@teamberry.io',
            twitter: 'http://twitter.com/andy_berry_dev',
            linkedin: 'https://www.linkedin.com/in/andy-berry-dev/',
            github: 'https://github.com/andy-berry-dev',
            stackoverflow:
                'https://stackoverflow.com/users/2634854/andy-berry-dev',
        },
    },
};

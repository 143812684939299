import React, { useState, useRef } from 'react';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { pdfjs, Document, Page } from 'react-pdf';
import {
    Flex,
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
    useToken,
    useBreakpointValue,
} from '@chakra-ui/react';
import Loader from '../Loader';
import useSiteMetadata from '../../hooks/useSiteMetadata';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

type PDFProps = {
    errorMessage?: string;
    noDataMessage?: string;
    file: string;
} & React.ComponentProps<typeof Document>;

const PDFError = ({
    file,
    errorMessage,
}: {
    file: string;
    errorMessage: string;
}) => {
    const { siteUrl } = useSiteMetadata();
    const absoluteFileUrl =
        typeof file === 'string'
            ? `${siteUrl.replace(/\/$/, '')}/${file?.replace(/^\//, '')}`
            : file;
    return (
        <Alert status="error" my={8}>
            <AlertIcon />
            <AlertDescription>
                {typeof absoluteFileUrl === 'string'
                    ? errorMessage.replace('<<file>>', absoluteFileUrl)
                    : errorMessage}
            </AlertDescription>
        </Alert>
    );
};

const PDFLoading = () => {
    const [loaderColour] = useToken('colors', ['gray.600']);
    return (
        <Box w="100%" my={12} textAlign="center">
            <Loader color={loaderColour} />
        </Box>
    );
};

const PDFNoData = ({ noDataMessage }: { noDataMessage: string }) => (
    <Alert status="warning" mx="auto" my={8}>
        <AlertIcon />
        <AlertDescription>{noDataMessage}</AlertDescription>
    </Alert>
);

const PDF = ({
    file,
    errorMessage = 'There was an error loading the document, please try again later',
    noDataMessage = 'No document found to display',
    ...props
}: PDFProps) => {
    const pageScale = useBreakpointValue([0.9, 0.9, 1]);
    const [numPages, setNumPages] = useState<number>();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const resizeObserver = useResizeObserver(containerRef);

    const onDocumentLoadSuccess = ({
        numPages: nextNumPages,
    }: PDFDocumentProxy): void => setNumPages(nextNumPages);

    return (
        <Flex
            ref={containerRef}
            my={4}
            sx={{
                '& .react-pdf__Document': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}
        >
            <Document
                file={file}
                error={<PDFError file={file} errorMessage={errorMessage} />}
                noData={<PDFNoData noDataMessage={noDataMessage} />}
                loading={<PDFLoading />}
                onLoadSuccess={onDocumentLoadSuccess}
                {...props}
            >
                {Array.from(new Array(numPages), (_el, index) => (
                    <Box
                        key={`page_${index + 1}`}
                        m={2}
                        mb={8}
                        boxShadow="0 0 8px rgba(0, 0, 0, 0.5)"
                    >
                        <Page
                            pageNumber={index + 1}
                            scale={pageScale}
                            width={resizeObserver?.width}
                        />
                    </Box>
                ))}
            </Document>
        </Flex>
    );
};

export default PDF;

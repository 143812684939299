import React, { forwardRef } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import type { GatsbyLinkProps } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby';
// import { OutboundLink } from 'react-ga';
import type { LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Box, Link, Link as OutboundLink } from '@chakra-ui/react';

import httpsUrl from '../../utils/https-url';
import useSiteMetadata from '../../hooks/useSiteMetadata';

const CanonicalLink = forwardRef<
    HTMLAnchorElement,
    | (GatsbyLinkProps<{}> & {
          showExternalLink?: boolean;
      })
    | (Omit<ChakraLinkProps, 'href'> & {
          to: string;
          showExternalLink?: boolean;
      })
>(({ to, showExternalLink = false, ...props }, ref) => {
    const { siteUrl } = useSiteMetadata();
    try {
        if (to.indexOf('/') === 0 && to.indexOf('//') !== 0) {
            return <Link ref={ref} as={GatsbyLink} to={to} {...props} />;
        }
        const url = new URL(httpsUrl(to || '')); // pass through httpsUrl so it's a valid URL
        if (url.origin === 'null') {
            // internal link - e.g. #someID
            return <Link ref={ref} href={to} {...props} />;
        }
        const { origin: siteOrigin, hostname: siteHostName } = new URL(siteUrl);
        if (url.hostname === siteHostName) {
            return (
                <Link
                    ref={ref}
                    as={GatsbyLink}
                    to={to.replace(siteOrigin, '')}
                    tabIndex={0}
                    {...props}
                />
            );
        }
        const { children, ...outboundLinkProps } = props;
        return (
            // ignore the eslint rule re. noopener noreferrer - OutboundLink does this for us
            // eslint-disable-next-line react/jsx-no-target-blank
            <Link
                ref={ref}
                as={OutboundLink}
                target="_blank"
                /* eventLabel={to} */ href={to}
                {...outboundLinkProps}
            >
                {children}
                {showExternalLink && (
                    <Box
                        as="span"
                        className="external-link"
                        fontSize="xs"
                        pl={1}
                        pr={1}
                    >
                        <FaExternalLinkAlt
                            fontSize="xs"
                            aria-label="Opens in a new window"
                        />
                    </Box>
                )}
            </Link>
        );
    } catch (e) {
        // incase the URL is invalid, use a basic link
        return (
            <Link
                ref={ref}
                target="_blank"
                rel="noopener noreferrer"
                href={to}
                {...props}
            />
        );
    }
});

export default CanonicalLink;

import React from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import rot from 'rot13-cipher';
import { Box, Link, HStack, Text, Flex } from '@chakra-ui/react';
import getIcon from '../../utils/get-icon';

const Footer = () => {
    const data = useStaticQuery<Queries.FooterQueryQuery>(graphql`
        query FooterQuery {
            site {
                siteMetadata {
                    title
                    subtitle
                    copyright
                    footerLinks {
                        label
                        path
                    }
                    author {
                        name
                        photo
                        bio
                        contacts {
                            twitter
                            github
                            email
                            linkedin
                            stackoverflow
                        }
                    }
                }
            }
        }
    `);

    const { subtitle, author, copyright, footerLinks } =
        data?.site?.siteMetadata || {};
    const { contacts } = author || {};

    return (
        <Flex
            color="blackAlpha.700"
            borderTop="1px"
            borderColor="gray.300"
            w="100%"
            mx="5"
            pt="5"
            pb="7"
            justifyContent="center"
            textAlign="center"
        >
            <Box w="3xl" maxW="4xl">
                <Text fontSize="sm" py="3">
                    {subtitle}
                </Text>
                <Text fontSize="xs" py="2">
                    © {author?.name}, <Text as="b">{copyright}</Text>.
                </Text>
                <HStack
                    justifyContent="center"
                    textAlign="center"
                    py="3"
                    spacing="3"
                >
                    {Object.entries(contacts || {}).map(([name, value]) => {
                        const href =
                            name === 'email'
                                ? `mailto:__ENCRYPTED__${rot(
                                      (value || '')?.replace('@', '_AT_'),
                                  )}`
                                : value;
                        return (
                            <Link
                                key={name}
                                href={href || ''}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="link dim black-50 dib br-100 mh2 lh-copy v-mid dim"
                            >
                                {getIcon(name)}
                            </Link>
                        );
                    })}
                </HStack>
                <HStack justifyContent="center" textAlign="center" py="2">
                    {(footerLinks || []).map((item) => (
                        <Link
                            as={GatsbyLink}
                            key={item?.path}
                            title={item?.label || ''}
                            to={item?.path || ''}
                            fontSize="sm"
                        >
                            {item?.label}
                        </Link>
                    ))}
                </HStack>
            </Box>
        </Flex>
    );
};

export default Footer;

import { extendTheme } from '@chakra-ui/react';

/*
    see https://github.com/chakra-ui/chakra-ui/tree/7a06603bd61e06e0591bf68d6dd50daad2c336af/packages/theme
    for default themes
*/

const theme = extendTheme({
    semanticTokens: {
        colors: {
            'chakra-body-text': {
                _light: 'gray.800',
                _dark: 'whiteAlpha.900',
            },
            'chakra-body-bg': {
                _light: 'white',
                _dark: 'gray.800',
            },
            'chakra-border-color': {
                _light: 'gray.200',
                _dark: 'whiteAlpha.300',
            },
            'chakra-inverse-text': {
                _light: 'white',
                _dark: 'gray.800',
            },
            'chakra-subtle-bg': {
                _light: 'gray.100',
                _dark: 'gray.700',
            },
            'chakra-subtle-text': {
                _light: 'gray.600',
                _dark: 'gray.400',
            },
            'chakra-placeholder-color': {
                _light: 'gray.500',
                _dark: 'whiteAlpha.400',
            },
        },
    },
    breakpoints: {
        base: '0em',
        sm: '30em',
        md: '48em',
        lg: '62em',
        xl: '80em',
        '2xl': '96em',
    },
    zIndices: {
        hide: -1,
        auto: 'auto',
        base: 0,
        docked: 10,
        dropdown: 1000,
        sticky: 1100,
        banner: 1200,
        overlay: 1300,
        modal: 1400,
        popover: 1500,
        skipLink: 1600,
        toast: 1700,
        tooltip: 1800,
    },
    radii: {
        none: '0',
        sm: '0.125rem',
        base: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
        full: '9999px',
    },
    blur: {
        none: 0,
        sm: '4px',
        base: '8px',
        md: '12px',
        lg: '16px',
        xl: '24px',
        '2xl': '40px',
        '3xl': '64px',
    },
    colors: {
        transparent: 'transparent',
        current: 'currentColor',
        black: '#000000',
        white: '#FFFFFF',
        whiteAlpha: {
            50: 'rgba(255, 255, 255, 0.04)',
            100: 'rgba(255, 255, 255, 0.06)',
            200: 'rgba(255, 255, 255, 0.08)',
            300: 'rgba(255, 255, 255, 0.16)',
            400: 'rgba(255, 255, 255, 0.24)',
            500: 'rgba(255, 255, 255, 0.36)',
            600: 'rgba(255, 255, 255, 0.48)',
            700: 'rgba(255, 255, 255, 0.64)',
            800: 'rgba(255, 255, 255, 0.80)',
            900: 'rgba(255, 255, 255, 0.92)',
        },
        blackAlpha: {
            50: 'rgba(0, 0, 0, 0.04)',
            100: 'rgba(0, 0, 0, 0.06)',
            200: 'rgba(0, 0, 0, 0.08)',
            300: 'rgba(0, 0, 0, 0.16)',
            400: 'rgba(0, 0, 0, 0.24)',
            500: 'rgba(0, 0, 0, 0.36)',
            600: 'rgba(0, 0, 0, 0.48)',
            700: 'rgba(0, 0, 0, 0.64)',
            800: 'rgba(0, 0, 0, 0.80)',
            900: 'rgba(0, 0, 0, 0.92)',
        },
        gray: {
            50: '#F7FAFC',
            100: '#EDF2F7',
            200: '#E2E8F0',
            300: '#CBD5E0',
            400: '#A0AEC0',
            500: '#718096',
            600: '#4A5568',
            700: '#2D3748',
            800: '#1A202C',
            900: '#171923',
        },
        red: {
            50: '#FFF5F5',
            100: '#FED7D7',
            200: '#FEB2B2',
            300: '#FC8181',
            400: '#F56565',
            500: '#E53E3E',
            600: '#C53030',
            700: '#9B2C2C',
            800: '#822727',
            900: '#63171B',
        },
        orange: {
            50: '#FFFAF0',
            100: '#FEEBC8',
            200: '#FBD38D',
            300: '#F6AD55',
            400: '#ED8936',
            500: '#DD6B20',
            600: '#C05621',
            700: '#9C4221',
            800: '#7B341E',
            900: '#652B19',
        },
        yellow: {
            50: '#FFFFF0',
            100: '#FEFCBF',
            200: '#FAF089',
            300: '#F6E05E',
            400: '#ECC94B',
            500: '#D69E2E',
            600: '#B7791F',
            700: '#975A16',
            800: '#744210',
            900: '#5F370E',
        },
        green: {
            50: '#F0FFF4',
            100: '#C6F6D5',
            200: '#9AE6B4',
            300: '#68D391',
            400: '#48BB78',
            500: '#38A169',
            600: '#2F855A',
            700: '#276749',
            800: '#22543D',
            900: '#1C4532',
        },
        teal: {
            50: '#E6FFFA',
            100: '#B2F5EA',
            200: '#81E6D9',
            300: '#4FD1C5',
            400: '#38B2AC',
            500: '#319795',
            600: '#2C7A7B',
            700: '#285E61',
            800: '#234E52',
            900: '#1D4044',
        },
        blue: {
            50: '#ebf8ff',
            100: '#bee3f8',
            200: '#90cdf4',
            300: '#63b3ed',
            400: '#4299e1',
            500: '#3182ce',
            600: '#2b6cb0',
            700: '#2c5282',
            800: '#2a4365',
            900: '#1A365D',
        },
        cyan: {
            50: '#EDFDFD',
            100: '#C4F1F9',
            200: '#9DECF9',
            300: '#76E4F7',
            400: '#0BC5EA',
            500: '#00B5D8',
            600: '#00A3C4',
            700: '#0987A0',
            800: '#086F83',
            900: '#065666',
        },
        purple: {
            50: '#FAF5FF',
            100: '#E9D8FD',
            200: '#D6BCFA',
            300: '#B794F4',
            400: '#9F7AEA',
            500: '#805AD5',
            600: '#6B46C1',
            700: '#553C9A',
            800: '#44337A',
            900: '#322659',
        },
        pink: {
            50: '#FFF5F7',
            100: '#FED7E2',
            200: '#FBB6CE',
            300: '#F687B3',
            400: '#ED64A6',
            500: '#D53F8C',
            600: '#B83280',
            700: '#97266D',
            800: '#702459',
            900: '#521B41',
        },
    },
    letterSpacings: {
        tighter: '-0.05em',
        tight: '-0.025em',
        normal: '0',
        wide: '0.025em',
        wider: '0.05em',
        widest: '0.1em',
    },
    lineHeights: {
        3: '.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        normal: 'normal',
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: '2',
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    fonts: {
        heading:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    },
    fontSizes: {
        '3xs': '0.45rem',
        '2xs': '0.625rem',
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '3.75rem',
        '7xl': '4.5rem',
        '8xl': '6rem',
        '9xl': '8rem',
    },
    sizes: {
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
        px: '1px',
        0.5: '0.125rem',
        1.5: '0.375rem',
        2.5: '0.625rem',
        3.5: '0.875rem',
        max: 'max-content',
        min: 'min-content',
        full: '100%',
        '3xs': '14rem',
        '2xs': '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
        '4xl': '56rem',
        '5xl': '64rem',
        '6xl': '72rem',
        '7xl': '80rem',
        '8xl': '90rem',
        prose: '60ch',
        container: {
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
        },
    },
    shadows: {
        xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
        sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
        inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
        none: 'none',
        'dark-lg':
            'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
    },
    space: {
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
        px: '1px',
        0.5: '0.125rem',
        1.5: '0.375rem',
        2.5: '0.625rem',
        3.5: '0.875rem',
    },
    borders: {
        none: 0,
        '1px': '1px solid',
        '2px': '2px solid',
        '4px': '4px solid',
        '8px': '8px solid',
    },
    transition: {
        property: {
            common: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
            colors: 'background-color, border-color, color, fill, stroke',
            dimensions: 'width, height',
            position: 'left, right, top, bottom',
            background:
                'background-color, background-image, background-position',
        },
        easing: {
            'ease-in': 'cubic-bezier(0.4, 0, 1, 1)',
            'ease-out': 'cubic-bezier(0, 0, 0.2, 1)',
            'ease-in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
        },
        duration: {
            'ultra-fast': '50ms',
            faster: '100ms',
            fast: '150ms',
            normal: '200ms',
            slow: '300ms',
            slower: '400ms',
            'ultra-slow': '500ms',
        },
    },
    components: {
        Button: {
            defaultProps: {
                colorScheme: 'blackAlpha',
                variant: 'solid',
            },
        },
        Card: {
            defaultProps: {
                variant: 'outline',
            },
        },
        Heading: {
            baseStyle: {
                fontFamily: 'heading',
                fontWeight: 'bold',
                sx: {
                    'section > &': {
                        mt: 4,
                    },
                },
            },
            sizes: {
                '4xl': {
                    fontSize: '4xl',
                    lineHeight: 1,
                    my: '6',
                },
                '3xl': {
                    fontSize: '3xl',
                    lineHeight: 1,
                    my: '5',
                },
                '2xl': {
                    fontSize: '2xl',
                    lineHeight: [1.2, null, 1],
                    my: '4',
                },
                xl: {
                    fontSize: 'xl',
                    lineHeight: [1.33, null, 1.2],
                    my: '3',
                },
                lg: {
                    fontSize: 'lg',
                    lineHeight: [1.33, null, 1.2],
                    my: '2',
                },
                md: {
                    fontSize: 'md',
                    lineHeight: 1.2,
                    my: '1',
                },
                sm: {
                    fontSize: 'sm',
                    lineHeight: 1.2,
                    my: '1',
                },
                xs: {
                    fontSize: 'xs',
                    lineHeight: 1.2,
                    my: '1',
                },
            },
            defaultProps: {
                size: 'xl',
            },
        },
        Link: {
            baseStyle: {
                transitionProperty: 'common',
                transitionDuration: 'fast',
                transitionTimingFunction: 'ease-out',
                cursor: 'pointer',
                textDecoration: 'underline',
                outline: 'none',
                color: 'inherit',
                _hover: {
                    textDecoration: 'none',
                },
                _focusVisible: {
                    boxShadow: 'outline',
                },
            },
        },
        Modal: {
            sizes: {
                xs: {
                    dialog: {
                        padding: 0,
                        maxW: 'xs',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                sm: {
                    dialog: {
                        padding: 0,
                        maxW: 'sm',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                md: {
                    dialog: {
                        padding: 0,
                        maxW: 'md',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                lg: {
                    dialog: {
                        padding: 0,
                        maxW: 'lg',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                xl: {
                    dialog: {
                        padding: 0,
                        maxW: 'xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                '2xl': {
                    dialog: {
                        padding: 0,
                        maxW: '2xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                '3xl': {
                    dialog: {
                        padding: 0,
                        maxW: '3xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                '4xl': {
                    dialog: {
                        padding: 0,
                        maxW: '4xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                '5xl': {
                    dialog: {
                        padding: 0,
                        maxW: '5xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                '6xl': {
                    dialog: {
                        padding: 0,
                        maxW: '6xl',
                    },
                    header: {
                        px: 4,
                        pb: 2,
                    },
                    body: {
                        px: 4,
                        pb: 2,
                    },
                    footer: {
                        px: 4,
                    },
                },
                full: {
                    dialog: {
                        padding: 0,
                        maxW: '100vw',
                        minH: '$100vh',
                        my: '0',
                        borderRadius: '0',
                    },
                },
            },
            defaultProps: {
                size: 'md',
            },
        },
    },
    styles: {
        global: {
            html: {
                overflowX: 'hidden',
            },
            body: {
                fontFamily: 'body',
                color: 'chakra-body-text',
                bg: 'chakra-body-bg',
                transitionProperty: 'background-color',
                transitionDuration: 'normal',
                lineHeight: 'base',
                overflowX: 'hidden',
            },
            '*::placeholder': {
                color: 'chakra-placeholder-color',
            },
            '*, *::before, &::after': {
                borderColor: 'chakra-border-color',
            },
        },
    },
    config: {
        useSystemColorMode: false,
        initialColorMode: 'light',
        cssVarPrefix: 'chakra',
    },
});

export default theme;

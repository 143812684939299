import React from 'react';
import {
    useStaticQuery,
    graphql,
    withPrefix,
    Link as GatsbyLink,
} from 'gatsby';
import {
    Flex,
    Box,
    Image,
    Heading,
    HStack,
    VStack,
    Link,
} from '@chakra-ui/react';

export const Navbar = () => {
    const data = useStaticQuery<Queries.NavbarQuery>(graphql`
        query Navbar {
            site {
                siteMetadata {
                    title
                    subtitle
                    copyright
                    menu {
                        label
                        path
                        className
                    }
                    author {
                        name
                        photo
                        bio
                        contacts {
                            twitter
                            github
                            email
                            linkedin
                        }
                    }
                }
            }
        }
    `);
    const { author, menu, title } = data?.site?.siteMetadata || {};

    return (
        <Flex
            width="100%"
            justifyContent="center"
            height="90px"
            position="relative"
            zIndex="banner"
            px={[2, 2, 2, 0]}
        >
            <HStack
                maxW="4xl"
                flexGrow="1"
                py="2"
                justifyContent={['center', 'left']}
            >
                <Box
                    alignSelf="center"
                    display={['none', 'flex']}
                    width="75px"
                    height="75px"
                >
                    <Link as={GatsbyLink} to="/" textDecoration="none">
                        <Image
                            src={withPrefix(author?.photo || '')}
                            width="75"
                            height="75"
                            alt={author?.name || ''}
                            borderRadius="full"
                        />
                    </Link>
                </Box>
                <VStack color="white" align="left">
                    <Heading size={['xs', 'sm']}>
                        <Link as={GatsbyLink} to="/" textDecoration="none">
                            {title}
                        </Link>
                    </Heading>
                    <HStack>
                        {(menu || []).map((item) => (
                            <Link
                                as={GatsbyLink}
                                key={item?.path}
                                title={item?.label || undefined}
                                to={item?.path || undefined}
                                className={
                                    item?.className
                                        ? `_nav-${item?.className}`
                                        : undefined
                                }
                                textTransform="uppercase"
                                textDecoration="none"
                                activeClassName="active"
                                fontSize={['xs', 'sm', 'md']}
                                sx={{
                                    '&.active': {
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                {item?.label || undefined}
                            </Link>
                        ))}
                    </HStack>
                </VStack>
            </HStack>
        </Flex>
    );
};

export default Navbar;

import React from 'react';
import type { HTMLChakraProps } from '@chakra-ui/react';
import {
    Box,
    Text,
    Heading,
    Img,
    OrderedList,
    UnorderedList,
    ListItem,
    Container,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Divider,
    Flex,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { FaDownload } from 'react-icons/fa6';
import Code from '../Code';
import CanonicalLink from '../CanonicalLink';
import PDF from '../PDF';

const shortcodes = {
    Image: Img,
    Heading,
    Box,
    Divider,
    Link: CanonicalLink,
    Flex,
    PDF,
    FaDownload,
    Text,
};

const component =
    <C extends React.JSXElementConstructor<any>>(
        Component: C,
        defaultProps?: React.ComponentProps<C>,
    ) =>
    // @ts-ignore
    ({ ...props }) => <Component {...defaultProps} {...props} />;

type Element =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'a'
    | 'ol'
    | 'ul'
    | 'li'
    | 'img'
    | 'strong'
    | 'i'
    | 'u'
    | 'abbr'
    | 'cite'
    | 'del'
    | 'em'
    | 'ins'
    | 'kbd'
    | 'mark'
    | 'code'
    | 's'
    | 'q'
    | 'samp'
    | 'sub'
    | 'sup'
    | 'hr'
    | 'figure'
    | 'figcaption'
    | 'table'
    | 'thead'
    | 'tbody'
    | 'tfoot'
    | 'tr'
    | 'th'
    | 'td'
    | 'caption'
    | 'pre';

type HeadingsMap = Record<
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    React.FC<{ children?: React.ReactNode } & HTMLChakraProps<any>>
>;
const headings: HeadingsMap = {
    h1: component(Heading, {
        as: 'h1',
        size: '2xl',
    }),
    h2: component(Heading, {
        as: 'h2',
        size: 'xl',
    }),
    h3: component(Heading, {
        as: 'h3',
        size: 'lg',
    }),
    h4: component(Heading, {
        as: 'h4',
        size: 'md',
    }),
    h5: component(Heading, {
        as: 'h5',
        size: 'sm',
    }),
    h6: component(Heading, {
        as: 'h6',
        size: 'sm',
    }),
};

export const createElements = (
    headerLevelStart: number = 1,
): Record<
    Element,
    React.FC<{ children?: React.ReactNode } & HTMLChakraProps<any>>
> => ({
    ...(() =>
        Array.from({
            length: Object.keys(headings).length,
        }).reduce<HeadingsMap>(
            (hs, _i, headingIndex, items) => ({
                ...hs,
                // @ts-ignore
                [`h${headingIndex + 1}`]: headings[
                    // if we're starting headings at h2 then h1 needs to be become h2 and so on
                    `h${Math.min(
                        headingIndex + headerLevelStart,
                        items.length,
                    )}`
                ] as any,
            }),
            headings,
        ))(),
    p: component(Text, {
        my: 3,
    }),
    a: ({ href, ...props }) => (
        <CanonicalLink wordBreak="break-word" to={href || ''} {...props} />
    ),
    ol: component(OrderedList, {
        listStylePosition: 'inside',
        marginInlineStart: '0.5rem',
    }),
    ul: component(UnorderedList, {
        listStylePosition: 'inside',
        marginInlineStart: '0.5rem',
    }),
    li: component(ListItem),
    img: component(Img, {
        maxWidth: '50%',
        mx: 'auto',
        my: 4,
    }),
    strong: component(Text, { as: 'strong' }),
    i: component(Text, { as: 'i' }),
    u: component(Text, { as: 'u' }),
    abbr: component(Text, { as: 'abbr' }),
    cite: component(Text, { as: 'cite' }),
    del: component(Text, { as: 'del' }),
    em: component(Text, { as: 'em' }),
    ins: component(Text, { as: 'ins' }),
    kbd: component(Text, { as: 'kbd' }),
    mark: component(Text, { as: 'mark' }),
    code: ({ children, className, ...props }) => {
        if (typeof children === 'string' && isNil(className)) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return <HtmlElements.pre {...props}>{children}</HtmlElements.pre>;
        }
        return (
            <Code className={className} {...props}>
                {children}
            </Code>
        );
    },
    pre: ({ children, className, ...props }) => {
        if (typeof children === 'string' && children?.includes('\n')) {
            return (
                <Text
                    as="pre"
                    display="block"
                    color="blackAlpha.900"
                    whiteSpace="break-spaces"
                    overflow="auto"
                    wordBreak="break-word"
                    className={className}
                    my={4}
                    {...props}
                >
                    {children}
                </Text>
            );
        }
        return (
            <Text
                as="span"
                fontFamily="monospace"
                fontSize="1rem"
                color="orange.600"
                className={className}
                {...props}
            >
                {children}
            </Text>
        );
    },
    s: component(Text, { as: 's' }),
    q: component(Text, { as: 'cite' }),
    samp: component(Text, { as: 'samp' }),
    sub: component(Text, { as: 'sub' }),
    sup: component(Text, { as: 'sup' }),
    hr: component(Divider),
    figure: component(Container, {
        as: 'figure',
        // maxW: "container.md",
        // my: 4,
        // sx: {
        //     "& > img": { margin: "0 auto" },
        // },
    }),
    figcaption: component(Text, {
        as: 'figcaption',
        // fontWeight: "bold",
        // align: "center",
    }),
    table: component(Table, { as: 'table', my: 8 }),
    thead: component(Thead, { as: 'thead' }),
    tbody: component(Tbody, { as: 'tbody' }),
    tfoot: component(Tfoot, { as: 'tfoot' }),
    tr: component(Tr, { as: 'tr' }),
    th: component(Th, { as: 'th' }),
    td: component(Td, { as: 'td' }),
    caption: component(TableCaption, { as: 'caption' }),
    ...shortcodes,
});

const HtmlElements = createElements(1);

export default HtmlElements;

import React, { useEffect, useState } from 'react';
import kebabCase from 'lodash/kebabCase';
import type {} from 'ldrs';
import type ldrs from 'ldrs';
import { useToken } from '@chakra-ui/react';
import useTimeout from 'beautiful-react-hooks/useTimeout';

// see https://www.npmjs.com/package/ldrs#options
type LdrsProps = {
    size?: number | string;
    color?: string;
    speed?: number | string;
    stroke?: number | string;
    strokeLength?: number | string;
    bgOpacity?: number | string;
};

type LoaderProps = LdrsProps & {
    loader?: keyof typeof ldrs;
    delay?: number;
};

const Loader = (props: LoaderProps = {}) => {
    const [showLoader, setShowLoader] = useState(false);
    const [defaultColour] = useToken('colors', ['gray.600']);
    const propsWithDefaults = {
        loader: 'quantum' as keyof typeof ldrs,
        color: defaultColour,
        delay: 200,
        ...props,
    };

    const { loader, delay } = propsWithDefaults;

    useEffect(() => {
        async function getLoader() {
            const ldrs = await import('ldrs');
            if (typeof ldrs[loader] !== 'undefined') {
                ldrs[loader]?.register();
            }
        }
        getLoader();
    }, [loader]);

    useTimeout(() => {
        setShowLoader(true);
    }, delay);

    if (!showLoader) {
        return null;
    }

    const propsMadeKebabCase = Object.entries(propsWithDefaults).reduce(
        (p, [key, value]) => ({
            ...p,
            [kebabCase(key)]: value,
        }),
        {},
    );

    const LLoader = `l-${loader}`;
    // @ts-ignore
    return <LLoader {...propsMadeKebabCase} />;
};

export default Loader;

import React from 'react';
import {
    FaTwitter,
    FaGithub,
    FaPaperPlane,
    FaLinkedin,
    FaStackOverflow,
} from 'react-icons/fa';

export default (name: string) => {
    switch (name) {
        case 'twitter':
            return <FaTwitter />;
        case 'github':
            return <FaGithub />;
        case 'email':
            return <FaPaperPlane />;
        case 'linkedin':
            return <FaLinkedin />;
        case 'stackoverflow':
            return <FaStackOverflow />;
        default:
            return null;
    }
};
